import React, { useContext } from "react";
import { Context } from "../Context";

export const Referral = () => {
  const { i18n } = useContext(Context);

  return (
    <div className="referral">
      <div className="referral_vector">
        <img src="referral.png" alt="referral" />
      </div>
      <div className="referral_content">
        <h2>{i18n("Refer PARiM and get £100")}</h2>
        <p>
          {i18n(
            "Enjoying PARiM? Share the experience with other business owners, companies or employers! Invite them, and get a £100 gift card for each new business that signs up for PARiM."
          )}
        </p>
        <a
          id="referral"
          rel="noopener noreferrer"
          href="https://parim.co/referral-campaign"
          target="_blank"
        >
          {i18n("Learn more")}
        </a>
      </div>
    </div>
  );
};
