import React, { Component } from "react";
import LangSwitcher from "./LangSwitcher";
import LoginProcess from "./LoginProcess";
import { ContextProvider, ContextConsumer } from "../Context";
import { version } from "../../package.json";
import { Referral } from "./Referral";

const supportUrl = { url: "support.parim.co" };
function linkifySupportUrl(text) {
  return text.replace(
    supportUrl.url,
    '<a href="https://' + supportUrl.url + '">' + supportUrl.url + "</a>"
  );
}

export default class Root extends Component {
  render() {
    return (
      <ContextProvider>
        <section id="login-container">
          <div className="loginView">
            <div className="loginView__loginArea">
              <a className="loginView__parim-logo" href="https://parim.co">
                <img
                  src="https://d3c5t20k88187g.cloudfront.net/productpage.parim.co.uk/system/parim-logo.svg"
                  height="52"
                  alt="PARiM"
                />
              </a>
              <div className="loginView__loginArea__wp">
                <div className="loginView__loginArea__top"></div>
                <div className="loginView__loginArea__fm">
                  <LoginProcess />
                </div>
                <div className="loginView__loginArea__bottom">
                  <ContextConsumer>
                    {(context) => (
                      <div
                        id="HelpMessage"
                        dangerouslySetInnerHTML={{
                          __html: linkifySupportUrl(
                            context.i18n(
                              "Need help? Read more on %{url}",
                              supportUrl
                            )
                          ),
                        }}
                      ></div>
                    )}
                  </ContextConsumer>
                  <br />
                  <LangSwitcher />
                </div>
              </div>
            </div>
            <div className="loginView__sideArea">
              <div className="loginView__sideArea__wp">
                <Referral />
                <div className="loginView__sideArea__vr">
                  <a
                    href="https://parim.co"
                    target="blank"
                    title="Workforce Management Software"
                  >
                    version {version}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContextProvider>
    );
  }
}
