import React, { Component } from 'react';
import { Context, LoginStep } from '../../Context';
import backIcon from './back.svg'
import forwardIcon from './forward.svg'

export default class LoginProcess extends Component {
  componentDidMount(){
    this.emailInput && this.emailInput.focus();
  }

  render() {
    let context = this.context

    return (
      <div id="login-form" className="loginForm">
        <div id="WelcomeBack">{context.i18n('Welcome back')}</div>

        <div id="messages">
          <div className="page-alert">
            <div className="errorSummary">
              <ul>
                <li>{context.i18n(context.errorMessage)}</li>
              </ul>
            </div>
          </div>
        </div>

        {context.loginStep === LoginStep.EmailEntry &&
          <React.Fragment>
            <div className={'row email-row cf ' + (context.isEmailInvalid ? 'error' : '')}>
              <label for="LoginForm_email">{context.i18n('Enter your email')}</label>
              <input
                id="LoginForm_email"
                ref={(input) => { this.emailInput = input; }}
                name="email"
                type="text" placeholder="example@email.com"
                value={context.email}
                readOnly={context.isLoading}
                onChange={() => { context.actions.onEmailChanged(this.emailInput.value) }}
                onKeyPress={(e) => { if (e.key === "Enter") context.actions.onEmailEntered() }}
                />
            </div>
            <div className="row submit-row cf">
              <input
                type="submit"
                value={context.i18n('Continue')}
                onClick={() => { context.actions.onEmailEntered() }}
                className={context.isLoading ? "loading" : ""}
                />
            </div>
          </React.Fragment>
        }

        {context.loginStep === LoginStep.TeamSelection &&
          <React.Fragment>
            <div className="row cf">
              {context.loginStep === LoginStep.TeamSelection &&
                <img className="Back" src={backIcon} alt={context.i18n('Back')} title={context.i18n('Back')} onClick={ () => { context.actions.onBack() }} />
              }
              <label>{context.i18n('Choose your team')}</label>
            </div>
            <div className="TeamList">
              {context.teams.map((team, index) => {
                let teamName = team.domain + (team.name != null && team.name.length > 0 ? (" – " + team.name) : "")
                let teamAddress = team.domain + '.parim.co'
                return (
                  <div key={index} className="Team" onClick={() => { context.actions.onTeamSelected(team.domain) }}>
                    <img className="Go" src={forwardIcon} alt=""/>
                    <div className="TeamName">{teamName}</div>
                    <div className="TeamAddress">{teamAddress}</div>
                  </div>
                )
              })}
            </div>
            </React.Fragment>
        }
      </div>
    );
  }
}
LoginProcess.contextType = Context