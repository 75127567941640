import en from './en.json'
import et from './et.json'
import ru from './ru.json'
import fr from './fr.json'
import i18n from 'roddeh-i18n'

const languages = [en, et, fr, ru].reduce((map, obj) => {
  map[obj.code] = {
    code: obj.code,
    name: obj.name,
    i18n: i18n.create(obj)
  }
  return map
}, {})

export default languages

