import React, { Component } from 'react';
import { Context } from '../../Context';

export default class Header extends Component {
  render() {
    let context = this.context

    window.c = context

    return (
      <React.Fragment>
        <select className="LangSwitcher" value={context.currentLanguageCode} onChange={(e) => context.actions.onLanguageChanged(e.target.value) }>
          {Object.values(context.languages).map(language => {
            return <option key={language.code} value={language.code}>{language.name}</option>
          })}
         </select>
      </React.Fragment>
    );
  }
}
Header.contextType = Context